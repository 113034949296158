.masthead {
  height: 100vh;
  padding-top: 69.75px;
}
@media only screen and (max-width: 767px) {
  .masthead {
    height: auto;
    min-height: 100vh;
  }
}
/*# sourceMappingURL=src/components/pages/home/components/MastHead.css.map */
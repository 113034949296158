


























@import '~@/styles/variables'

.gray-bg
  background-color #c0c5ce
  opacity 0.7

.navbar-toggler
  margin-right 1rem

.contact-us-bg
  background-image url('~@/components/layouts/landingLayout/assets/images/contact-us-bg.png')
  background-size contain
  background-repeat no-repeat
  background-position center top
  padding 0 105px
  @media mobile
    padding 0 15px

.text-contact-us
  position center
  font-family 'Roboto', sans-serif
  font-style normal
  font-weight bold
  font-size 3rem
  line-height 5rem
  text-align center

.et-contact-us
  background #FFFFFF
  border 2px solid #03396C
  box-sizing border-box
  border-radius 5px
  padding 5px 10px
  font-family 'Roboto', sans-serif
  font-style normal
  font-weight normal
  font-size 1.2rem
  line-height 2rem
  color #000000

.btn-submit, .btn-submit:active
  background #03396C !important
  border 3px solid #03396C !important
  box-sizing border-box !important
  border-radius 30px !important
  font-family 'Roboto', sans-serif
  font-style normal
  font-weight bold !important
  font-size 1.2rem
  color #ffffff !important
  margin-top 2rem !important

.btn-submit:hover
  background #ffffff !important
  border 3px solid #03396C !important
  color #03396C !important

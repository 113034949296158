.gray-bg {
  background-color: #c0c5ce;
  opacity: 0.7;
}
.navbar-toggler {
  margin-right: 1rem;
}
.my-background {
  background-image: url("../../layouts/landingLayout/assets/images/landingpage-bg-0003.png");
  background-color: #fff;
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center;
  width: 100% !important;
  max-width: 100% !important;
  align-items: center;
}
.my-container {
  position: absolute;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  overflow: hidden;
}
.circle-card {
  background: #fff;
  border: 3px solid #1dd8e4;
  box-sizing: border-box;
  align-items: center;
  border-radius: 50%;
  position: absolute;
  box-shadow: 0px 1vw 1.2vw rgba(3,57,108,0.45);
  transition: all 0.3s;
}
.circle-card:hover {
  box-shadow: 0px 1.2vw 1.5vw rgba(3,57,108,0.65);
  transition: all 0.3s;
}
.circle-card-container {
  position: absolute;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  padding: 20px;
  align-items: center;
  display: flex;
  justify-content: center;
}
.card-text {
  font-family: 'Roboto Condensed', sans-serif;
  font-style: normal;
  font-weight: bold;
  font-size: 1.4vw;
  line-height: 2vw;
  color: #03396c;
}
.circle-fade-in {
  animation-name: circleFadeIn;
  -webkit-animation-name: circleFadeIn;
}
@-moz-keyframes circleFadeIn {
  0% {
    opacity: 0;
    -webkit-transform: scale3d(0.1, 0.1, 0.1);
    transform: scale3d(0.1, 0.1, 0.1);
    left: 0%;
    top: 0%;
  }
  to {
    opacity: 1;
    -webkit-transform: scale3d(1, 1, 1);
    transform: scale3d(1, 1, 1);
  }
}
@-webkit-keyframes circleFadeIn {
  0% {
    opacity: 0;
    -webkit-transform: scale3d(0.1, 0.1, 0.1);
    transform: scale3d(0.1, 0.1, 0.1);
    left: 0%;
    top: 0%;
  }
  to {
    opacity: 1;
    -webkit-transform: scale3d(1, 1, 1);
    transform: scale3d(1, 1, 1);
  }
}
@-o-keyframes circleFadeIn {
  0% {
    opacity: 0;
    -webkit-transform: scale3d(0.1, 0.1, 0.1);
    transform: scale3d(0.1, 0.1, 0.1);
    left: 0%;
    top: 0%;
  }
  to {
    opacity: 1;
    -webkit-transform: scale3d(1, 1, 1);
    transform: scale3d(1, 1, 1);
  }
}
@keyframes circleFadeIn {
  0% {
    opacity: 0;
    -webkit-transform: scale3d(0.1, 0.1, 0.1);
    transform: scale3d(0.1, 0.1, 0.1);
    left: 0%;
    top: 0%;
  }
  to {
    opacity: 1;
    -webkit-transform: scale3d(1, 1, 1);
    transform: scale3d(1, 1, 1);
  }
}
/*# sourceMappingURL=src/components/pages/home/LandingPageWhy.css.map */
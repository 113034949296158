












@import '~@/styles/variables'

.masthead
  height 100vh
  padding-top 69.75px

  @media mobile
    height auto
    min-height 100vh





















.crypitor-features
  background url('../assets/crypitor-features-bg.png')
  background-size cover
  background-position center
  background-repeat no-repeat







































.gray-bg {
  background-color: #c0c5ce;
  opacity: 0.7;
}

.navbar-toggler {
  margin-right: 1rem;
}

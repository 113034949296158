
















.gray-bg
  background-color #c0c5ce
  opacity 0.7

.navbar-toggler
  margin-right 1rem

.benefi-bg-2
  background-color #ffffff
  background-image url('~@/components/layouts/landingLayout/assets/images/landingpage-bg-0001.jpg')
  background-size cover
  padding 100px

.benefit-text
  font-family 'Roboto Condensed', sans-serif
  font-style normal
  font-weight bold
  font-size 2rem
  line-height 3.5rem
  align-items center
  text-align center
  color #FFFFFF

.btn-navbar
  font-size 15px !important
  font-weight 450 !important

.bg-container {
  background: #fff !important;
  margin: 0px !important;
}
.card-beautiful {
  background: #fff !important;
  opacity: 1 !important;
  border: 1px solid #c4c4c4 !important;
  box-sizing: border-box !important;
  box-shadow: 0px 5px 10px rgba(3,57,108,0.4) !important;
  border-radius: 15px !important;
  max-width: 286px !important;
  min-height: 372px !important;
  margin: 4rem !important;
  transition: all 0.3s;
}
.card-beautiful:hover {
  box-shadow: 0px 7px 15px rgba(3,57,108,0.8) !important;
  margin: 3.5rem 4rem 4.5rem 4rem !important;
  transition: all 0.3s;
}
.card-item {
  margin-top: 2.5rem !important;
  margin-bottom: 2.5rem !important;
}
.card-image {
  width: 40% !important;
  padding: 3rem !important;
  background-size: contain;
  background-repeat: no-repeat;
  background-position: center;
}
.card-cost {
  background-image: url("../../layouts/landingLayout/assets/images/hand.png");
}
.card-performance {
  background-image: url("../../layouts/landingLayout/assets/images/highperformance.png");
}
.card-usability {
  background-image: url("../../layouts/landingLayout/assets/images/usability.png");
}
.card-title {
  font-family: 'Roboto Condensed', sans-serif;
  font-weight: bold;
  font-size: 2rem;
  line-height: 2.1rem;
  align-items: center;
  text-align: center;
  color: #1dd8e4;
}
.card-description {
  font-family: 'Roboto Condensed', sans-serif;
  font-weight: 300;
  font-size: 1.2rem;
  line-height: 1.3rem;
  align-items: center;
  text-align: center;
  color: #03396c;
}
.btn-learmore {
  margin-bottom: 4rem !important;
  font-size: 1.3rem !important;
}
/*# sourceMappingURL=src/components/pages/home/LandingPageBenefit.css.map */









































@import '~@/styles/variables'

.card.card-service
  background #FFFFFF
  border 1px solid rgba(1, 35, 69, 0.2)
  box-shadow 0px 0px 5px rgba(196, 196, 196, 0.2)
  width 269px
  height 299px

  .card-body
    padding-top 39px
    padding-bottom 39px


























@import '~@/styles/variables'

@media mobile
  img
    width 98px
